import axios from 'axios';
import authHeader from './auth/auth-header';
import authService from "./auth/auth.service";

// const BASE_API_URL = process.env.REACT_APP_BASE_API_URL; // Questo dato è scritto nei file .env.
const BASE_API_URL = window.Configs?.JS_BASE_API_URL ? window.Configs.JS_BASE_API_URL : process.env.REACT_APP_BASE_API_URL; // Questo dato è scritto nei file .env.

const PROPERTY_ID = process.env.REACT_APP_PROPERTY_ID; // Questo dato è scritto nei file .env.
const API_URL = BASE_API_URL + 'vr/Reservations';

axios.interceptors.response.use(
  (response) => {
    // Modify the response data or handle the response
    return response;
  },
  (error) => {
    console.log('Response error: ', error);
    // Handle response errors
    if (error.response.status === 401) {
      authService.logout();
      window.location.href = '/#/login';
    }
    return Promise.reject(error);
  }
);

export class ReservationsService {

    getPublicReservations(property_id = PROPERTY_ID) {
        return axios.get(`${API_URL}/${property_id}`).then(res => res.data.data);
    }

    getReservations(property_id = PROPERTY_ID, params = '') {
        return axios.get(`${API_URL}/Details/${property_id}?${params}`, {headers: authHeader()}).then(res => {
            return res.data;
        });
    }

    getInvalidDates(property_id = PROPERTY_ID) {
        return axios.get(`${API_URL}/InvalidDates/${property_id}`).then(res => {
            return res.data;
        });
    }

    getWebsiteDates(year, property_id = PROPERTY_ID) {
        return axios.get(`${API_URL}/Website/${property_id}/${year}`).then(res => {
            return res.data;
        });
    }

    getReservation(id) {
      return axios.get(`${API_URL}/${id}`, {headers: authHeader()}).then(res => {
        return res.data;
      });
    }

    postReservation(reservation) {
      return axios.post(`${API_URL}`, reservation, {headers: authHeader()}).then(res => {
        return res.data;
      });
    }

    putReservation(reservation) {
        return axios.put(`${API_URL}/${reservation.id}`, reservation, {headers: authHeader()}).then(res => {
            return res.data;
        });
    }

    deleteReservation(id) {
        return axios.delete(`${API_URL}/${id}`, {headers: authHeader()}).then(res => {
            return true;
        });
    }

    getICal(property_id) {
      window.open(`${API_URL}/iCal/${property_id}`, "_blank");
      /*return axios.get(`${API_URL}/iCal/${property_id}`).then(res => {
        return res.data;
      });*/
    }

}
