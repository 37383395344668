import React, { Component } from 'react';
import { Galleria } from 'primereact/galleria';
import css from './Galleria.module.css';


export class GalleriaFullScreen extends Component {

  constructor(props) {
    super(props);

    // console.log(props);

    this.state = {
      images: null,
      activeIndex: 0
    };

    this.itemTemplate = this.itemTemplate.bind(this);
    this.thumbnailTemplate = this.thumbnailTemplate.bind(this);
    this.onItemChange = this.onItemChange.bind(this);

    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 5
      },
      {
        breakpoint: '768px',
        numVisible: 3
      },
      {
        breakpoint: '560px',
        numVisible: 1
      }
    ];
  }

  componentDidMount() {
    // this.galleriaService.getImages().then(data => this.setState({ images: data }));
    let imgs = [];
    for(let i = +this.props.startPic; i <= +this.props.endPic; i++) {
      let index = i.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      });
      let item = {
          itemImageSrc: "/assets/FotoVillaMagnolia/" + index + "_Villa_Magnolia_xxl.jpg",
          thumbnailImageSrc: "/assets/FotoVillaMagnoliaTn/" + index + "_Villa_Magnolia_xxl.jpg",
          alt: "Villa Magnolia Como Lake",
      };
      imgs.push(item)
    }


    this.setState({images: imgs})
  }

  onItemChange(event) {
    this.setState({ activeIndex: event.index });
  }

  itemTemplate(item) {
    // console.log(item.itemImageSrc);
    return <img src={item.itemImageSrc} alt={item.alt} style={{ width: '100%', display: 'block' }} />;
  }

  thumbnailTemplate(item) {
    return <img src={item.thumbnailImageSrc} alt={item.alt} style={{ display: 'block', width: 100 + 'px' }} />;
  }

  render() {
    return (
      <>
        {/*Versione 1 carosello sempre visibile con thumbnails sotto*/}
        {/*<Galleria value={this.state.images} responsiveOptions={this.responsiveOptions} numVisible={7} circular style={{ maxWidth: '800px', margin: 'auto' }}*/}
        {/*          item={this.itemTemplate} thumbnail={this.thumbnailTemplate} />*/}


        {/*Versione 2 con miniature tutte affiancate e lightbox*/}
        <Galleria ref={(el) => this.galleria = el} value={this.state.images} responsiveOptions={this.responsiveOptions} numVisible={7} style={{ maxWidth: '850px' }}
                  activeIndex={this.state.activeIndex} onItemChange={(e) => this.setState({ activeIndex: e.index })}
                  circular fullScreen showItemNavigators showThumbnails={false} item={this.itemTemplate} thumbnail={this.thumbnailTemplate}/>
        <div className={css.gridGallery + " mt-4"}>
          {
            this.state.images && this.state.images.map((image, index) => {
              let imgEl = <img src={image.thumbnailImageSrc} alt={image.alt} style={{ cursor: 'pointer', width: 120 + 'px', borderRadius: '3px' }} onClick={() => {
                this.setState({ activeIndex: index }, () => this.galleria.show());
              }} />

              return (
                <div className="col" key={index}>
                  {imgEl}
                </div>
              )
            })
          }
        </div>
      </>
    );
  }
}
