import React from 'react';

export const Footer = (props) => {

    return (
        <div className="layout-footer">
            {/*<img src={props.layoutColorMode === 'light' ? 'assets/layout/images/logo-dark.svg' : 'assets/layout/images/logo-white.svg'} alt="Logo" height="20" className="mr-2" />*/}
          <small>
            2021-{new Date().getFullYear()} © Villa Magnolia Lake Como
            <br />Via Cadorna, 8 - Oliveto Lario
              <a href="mailto:info@villamagnolialakecomo.it"  className="ml-1 mr-1" style={{ color: "var(--surface-900)" }}> info@villamagnolialakecomo.it </a>
              <br />CIR: <span style={{ color: "red" }} >xxxxxx-CNI-xxxxx</span> - CIN : <span style={{ color: "red" }} >xxxxxx</span></small>
        </div>
    );
}
